<!-- src/components/Feed.vue -->
<template>
  <v-container class="fill-height">
    <v-row class="justify-center align-start fill-height" no-gutters>
      <v-col cols="12" md="8" style="max-width:500px">
        <!-- Page content -->
        <v-row no-gutters v-if="!loading">
          <v-col cols="12" v-if="isUserAdminOrSU" class="mb-4">
            <PostForm :availableUsers="availableUsers" @create="createPost" />
          </v-col>

          <v-col cols="12">
            <v-row no-gutters>


              <v-col cols="12" class="mb-4" v-for="(post, index) in sortedPosts" :key="post.id">
                <PostForm v-if="selectedPost && selectedPost.id === post.id" :value="selectedPost"
                  :availableUsers="availableUsers" @update="updatePost" @cancelEdit="cancelEdit" />

                <PostDisplay :key="post.id + 'display'" v-if="
                  !selectedPost ||
                  (selectedPost && selectedPost.id !== post.id)
                " :value="post" @openEdit="selectedPost = { ...post }" @update="updatePost" @delete="deletePost" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- Skeletons -->
        <template v-if="loading">
          <v-skeleton-loader class="mb-4" type="card,actions" v-if="isUserAdminOrSU" />
          <v-divider v-if="isUserAdminOrSU" />
          <v-skeleton-loader class="mb-4" type="article,actions" />
          <v-skeleton-loader class="mb-4" type="article,actions" />
          <v-skeleton-loader class="mb-4" type="article,actions" />
        </template>

        <!-- Empty page -->
        <template v-if="!loading && !posts.length">
          <v-alert type="info" color="primary darken-2">
            {{ isUserAdminOrSU ? "No hay posts, crea uno!" : "No hay posts" }}
          </v-alert>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  collection,
  getDocs,
  doc,
  getDoc,
  runTransaction,
  deleteDoc,
  updateDoc,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { ref as storageRef, getStorage, deleteObject } from "firebase/storage";
import PlanProgress from "@/components/PlanProgress.vue";
import PostForm from "@/components/feed/form/PostForm.vue";
import PostDisplay from "@/components/feed/post/PostDisplay.vue";

export default {
  name: "Feed",

  components: {
    PlanProgress,
    PostForm,
    PostDisplay,
  },

  data() {
    return {
      posts: [],
      loading: true,
      loadingToggleLike: false,
      availableUsers: [],
      selectedPost: null,
    };
  },

  async mounted() {
    if (this.isUserAdminOrSU) {
      await this.fetchAvailableUsers();
    }
    await this.fetchPosts();
  },

  computed: {
    isUserAdminOrSU() {
      const role = this.$store.state.Auth.token.claims.type;
      return role && ["admin", "superuser"].includes(role);
    },
    sortedPosts() {
      return this.posts.sort((a, b) => b.createdAt - a.createdAt);
    },
  },

  methods: {
    async fetchPosts() {
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, "posts"));
      let posts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // create a unique lists of user id who posted and get their displayName
      const userIds = [...new Set(posts.map((post) => post.created.user))];
      const userNames = await Promise.all(
        userIds.map(async (userId) => {
          const userDoc = doc(db, "users", userId);
          const userSnap = await getDoc(userDoc);
          return userSnap.data().displayName;
        })
      );

      // Add displayName to each post
      posts = posts.map((post) => ({
        ...post,
        created: {
          ...post.created,
          user: post.created.user,
          displayName: userNames[userIds.indexOf(post.created.user)],
        },
      }));



      // Filter posts based on visibility
      const userId = this.$store.state.Auth.token.claims.user_id;
      if (!this.isUserAdminOrSU) {
        posts = posts.filter(
          (post) =>
            post.visibility.includes("all") || post.visibility.includes(userId)
        );
      }

      // Sort posts by date
      posts = posts.sort((a, b) => b.created.date - a.created.date);

      this.posts = posts;
      this.loading = false;
    },
    async fetchAvailableUsers() {
      const db = getFirestore();
      const q = query(collection(db, "users"), where("disabled", "==", false));
      const querySnapshot = await getDocs(q);
      let users = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => {
          if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
            return -1;
          }
          if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
            return 1;
          }
          return 0;
        });

      this.availableUsers = users;
    },
    isLikedByMe(postLikes) {
      postLikes.includes(this.$store.state.Auth.token.claims.user_id);
    },
    async deleteMedia(post) {
      let confirm = await this.$confirm(
        "¿Está seguro de que desea eliminar el archivo adjunto?",
        {
          color: "error",
          title: "Advertencia",
          icon: "mdi-alert-circle",
          buttonTrueText: "confirmar",
        }
      );

      if (!confirm) return;

      const storage = getStorage();
      const fileRef = storageRef(storage, `media/${post.filename}`);
      await deleteObject(fileRef);

      // update post
      const db = getFirestore();
      const postRef = doc(db, "feed", post.id);
      await updateDoc(postRef, { mediaUrl: null });

      this.fetchPosts();
      this.$notify({
        title: "Archivo eliminado",
        text: "El archivo adjunto ha sido eliminado con éxito",
        type: "success",
      });
    },
    createPost(post) {
      this.posts.unshift(post);
    },
    updatePost(post) {
      const postIndex = this.posts.findIndex((e) => e.id === post.id);
      if (postIndex !== -1) {
        this.$set(this.posts, postIndex, post);
      }

      if (this.selectedPost) {
        this.selectedPost = null;
      }
    },
    deletePost(post) {
      const postIndex = this.posts.findIndex((e) => e.id === post.id);
      if (postIndex !== -1) {
        this.posts.splice(postIndex, 1);
      }
    },
    cancelEdit() {
      this.selectedPost = null;
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.85;
  position: absolute;
  width: 100%;
}
</style>
